@import '../mixins';

.nav-tabs {
    border: none !important;
    margin-bottom: -10px !important;
    li {
        .nav-link {
            color: $dark;
            border: none;
            padding: 0 1rem;
            background-color: transparent !important;

            span {
                display: block;
                width: 100%;
                margin-top: .5rem;
                height: 2px;
            }
            &:hover {
                border: none;
                span {
                    background: $color-black-100;
                }
            }
            &.active {
                border: none;
                font-family: $font-family-regular;
                color: $dark;
                span {
                    background: $info;
                }
            }
        }
        &:first-child{
            .nav-link {padding-left: 0;}

        }
    }


}
