.create-flow-starter {
    position: relative;
    padding: 0 65px;
    .card-hover {
        &:hover {
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        }
    }
    .btn-create-own-flow {
        width: 50px;
        height: 50px;
        padding: 0;
        text-align: center;
        line-height: 60px;
        i {
            font-size: 1.5rem;
        }
    }
}
.create-flow-form {
    width: 40%;
    position: relative;
}
