@import "../mixins";

.page-content {
    margin-left: 115px;
    @include media-breakpoint-up(xl) {
        margin-left: 325px;
    }
    .page-content-inner {
        .page-content-header {
            padding: 30px 20px 10px 20px;

            @include media-breakpoint-up(lg) {
                padding: 30px 40px 10px 40px;
            }
        }
        .page-content-body {
            padding: 20px;
            @include media-breakpoint-up(lg) {
                padding: 20px 40px;
            }
        }
        .breadcrumb {
            padding: 0;
            background: none;
            margin-bottom: 0.5rem;
            a {
                color: $color-black-300;
            }
        }
    }
    &.page-content-full {
        margin-left: 75px;
        background: $color-black-dark;
        height: 100%;
        .page-content-inner {
            border-top-left-radius: 1rem;
            background: #ffffff;
            .page-content-header {
                border-top-left-radius: 1rem;
                &.bg-light {
                    padding: 20px;

                    @include media-breakpoint-up(lg) {
                        padding: 20px 40px 20px 40px;
                    }
                }
            }
        }
    }
}

.indicator {
    width: 10px;
    height: 10px;
    background: $color-black-200;
    border-radius: 100%;
    display: inline-block;
}
.indicator-sm {
    width: 7px;
    height: 7px;
}
.indicator-todo {
    background: $color-blue;
}
.indicator-approved {
    background: $color-green;
}
.indicator-completed {
    background: $color-green;
}
.indicator-rejected {
    background: $color-red;
}
.indicator-withdrawn {
    background: $color-yellow;
}
.indicator-cancelled {
    background: $color-red;
}
.indicator-warning {
    background: $warning;
}
.indicator-danger {
    background: $danger;
}
.custom-list {
    min-height: 48px;
    position: relative;
    &:hover {
        background: $light;
    }
    .custom-list-edit {
        position: absolute;
        left: 0;
        top: -7px;
        width: 100%;
        background: #fff;
        display: block;
        z-index: 50;
        border-bottom: 1px solid $color-black-light;
        border-top: 1px solid $color-black-light;
    }
}
.actions,
.actions-wrapper {
    min-width: 100px;
    min-height: 30px;
}
.actions-on-hover {
    .actions {
        display: none;
    }
    &:hover {
        .actions {
            display: block;
        }
    }
}
.comment-input {
    .form-control {
        border-bottom: 0;
        border-radius: 3px 3px 0 0;
        outline: none !important;
        box-shadow: none !important;
        border-color: $color-black-100 !important;
        resize: none;
    }
    .comment-input-footer {
        border: 1px solid $color-black-100;
        padding: 7px 15px;
        border-radius: 0 0 3px 3px;
        border-top: none;
    }
}
.avatar {
    width: 40px;
    height: 40px;
    border: solid 1px $color-black-100;
    border-radius: 100%;
    background-color: $color-black-300;
    display: block;
    > img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: block;
    }
    &.avatar-sm {
        width: 40px;
        height: 40px;
    }
    &.avatar-xs {
        width: 15px;
        height: 15px;
    }
}
.avatar-with-label {
    display: flex;
    &.avatar-badge {
        background: $color-black-light;
        display: inline-flex;
        border-radius: 3px;
        align-items: center;
        font-size: 12px;
        .avatar {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            line-height: 18px;
            border: none;
            margin-left: 5px;
        }
        .avatar-remove {
            width: 24px;
            height: 24px;
            padding: 0;
            line-height: 24px;
            text-align: center;
            border-radius: 0 3px 3px 0;
            margin-left: 2px;
            &:hover {
                text-decoration: none;
                background: $color-black-100;
            }
        }
    }
}
.avatar-group {
    display: flex;
    .avatar {
        margin-left: -4px;
        &:first-child {
            margin: 0;
        }
    }
}
.hide-roller {
    width: 50px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 50;
}
.btn-chevron {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    text-align: center;
    line-height: 27px;
    padding: 0;
    margin: 20px -12px 0 0;
    i {
        font-size: 1rem;
    }
}
.btn-chevron-right {
    margin: 20px 0 0 -12px;
}

/* This is for condtions OR and and buttons*/
.add-condtions {
    .btn-light {
        border-left: 1px solid $color-black-200;
        &:first-child {
            border-left: 1px solid $color-black-light;
        }
    }
}
@mixin fixed-column {
    position: sticky;
    right: 0px;
    box-shadow: inset 1px 0 0 0 $color-black-light;
}
/* Create table*/
.editable-table {
    .column-settings {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 130px;
        background: rgba(49, 122, 226, 0.2);
        z-index: 50;
        display: none;
    }
    th {
        min-width: 224px;
        position: relative;

        &:hover {
            .column-settings {
                display: flex;
            }
        }
    }
    td {
        // padding: 20px !important;
        min-width: 224px;
        position: relative;
    }
}
.editable-table-view {
    td {
        padding: 20px !important;
        min-width: 200px;
    }
}
/* Field type dropdown*/
.dropdown-field-type {
    .btn-input {
        padding: 0 12px;
        border: solid 1px $color-black-100;
        height: 35px;
        width: 100%;
        &::after {
            position: absolute;
            right: 12px;
            top: 16px;
            color: $color-black-300;
        }
    }
    .dropdown-menu {
        width: 470px;
        .dropdown-field-btns {
            max-height: 250px;
            overflow-y: auto;
        }
    }
}
.btn-chevron {
    width: 26px;
    height: 26px;
    border-radius: 100% !important;
    text-align: center !important;
    line-height: 27px !important;
    padding: 0 !important;
    margin: 20px -12px 0 -13px !important;
    i {
        font-size: 1rem;
    }
}
.btn-chevron-right {
    margin: 20px 0 0 -12px;
}
.hide-roller {
    width: 50px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 50;
}
.flow-card {
    .more-options {
        width: 40px;
        .btn {
            display: none;
        }
    }
    &:hover {
        .more-options {
            .btn {
                display: block;
            }
        }
    }
    .flow-card-link {
        color: $color-black !important;
        text-decoration: none !important;
    }
}

.email-wrapper {
    width: 170px;
    @include media-breakpoint-up(lg) {
        width: 250px;
    }
    @include media-breakpoint-up(xl) {
        width: 200px;
    }
}

.id-icons-30 {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 32px;
    background: $color-black-light;
    i {
        font-size: 1rem;
    }
}
.id-icons-35 {
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 38px;
    background: $color-black-light;
    i {
        font-size: 1.1rem;
    }
}
.id-icons-40 {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 45px;
    background: $color-black-light;
    i {
        font-size: 1.3rem;
    }
}

.callout-light {
    border-left: solid 3px $color-black-100;
}
.callout-primary {
    border-left: solid 3px $primary !important;
}
.callout-brand {
    border-left: solid 3px $brand !important;
}
.callout-warning {
    border-left: solid 3px $warning !important;
}
.callout-danger {
    border-left: solid 3px $danger !important;
}

.text-bg-yellow {
    color: $color-yellow-dark !important;
    background-color: $color-yellow-light !important;
    i {
        color: $color-yellow-dark !important;
    }
}

.text-bg-blue {
    color: $color-blue-dark;
    background-color: $color-blue-light;
    i {
        color: $color-blue-dark;
    }
}

.text-bg-green {
    color: $color-green-dark !important;
    background-color: $color-green-light !important;
    i {
        color: $color-green-dark !important;
    }
}

.text-bg-red {
    color: $color-red-dark !important;
    background-color: $color-red-light !important;
    i {
        color: $color-red-dark !important;
    }
}

.text-bg-purple {
    color: $color-purple-dark !important;
    background-color: $color-purple-light !important;
    i {
        color: $color-purple-dark !important;
    }
}

.dropdown-users-list {
    .dropdown-menu {
        max-height: 300px;
        overflow-y: auto;
        .users-list {
            border-bottom: 1px solid $color-black-light;
            &:last-child {
                border: none;
            }
        }
    }
}
.users-list {
    cursor: pointer;
    .user-name {
        margin-bottom: -4px;
    }
    .actions-wrapper {
        width: 30px;
        min-width: auto;
        .actions {
            width: 30px;
            min-width: auto;
        }
    }
    &:hover {
        background: $color-black-lighter;
    }
}

.display-1 {
    font-size: $h1-font-size !important;
    font-family: $font-family-medium !important;
}

.login-signup {
    width: 100%;
    max-width: 450px;
    margin: auto;

    .login-signup-inner {
        background-color: #fff;
        padding: 40px;

        h1 {
            font-size: 2rem;
            font-weight: bold;
        }
    }
}

.btn-circle {
    border-radius: 50px !important;
    padding-left: 4px !important;
    height: 30px !important;
    width: 30px !important;
    i {
        width: unset;
        padding-bottom: 25px;
    }
}