@import "../../../assets/styles/mixins";
@import "../../../../node_modules/boxicons/css/boxicons.css";
.btn {
    font-family: $font-family-regular;
    // min-width: 100px;
}
.btn-light {
    background: $color-black-light;
    border-color: $color-black-light;
}
.mw {
    min-width: 100px;
}
.btn-outline-gray {
    color: $dark;
    border-color: $color-black-100 !important;
    font-size: 14px;
    font-family: $font-family-base !important;
    font-weight: normal;
    &:hover {
        background: $color-black-lighter;
    }
    @mixin hover-active {
        color: $info;
        border-color: $info;
        background: $color-blue-lighter;
    }

    &.active {
        @include hover-active;
    }
}
.btn-icon {
    width: 40px;
    height: 35px;
    text-align: center;
    padding: 0;
    line-height: 42px;

    i {
        font-size: 1.3rem;
    }
    &:hover {
        background: $color-black-light;
    }
    &.btn-icon-sm {
        width: 30px;
        height: 30px;
        line-height: 30px;
        i {
            font-size: 1rem;
        }
    }
}
.btn-icon-all {
    padding-top: 7px;
    padding-bottom: 5px;
    i {
        font-size: 1rem;
    }
}
.nav-pills {
    .nav-item {
        .nav-link {
            color: $dark;
            &:hover {
                background: $color-black-lighter;
            }
            @mixin hover-active {
                color: $info;
                background: $color-blue-lighter;
            }
            &.active {
                @include hover-active;
            }
        }
    }
}
