@import "../../../assets/styles/mixins";
@import "../../../assets/styles/bootstrap/bootstrap";

.submenu {
    width: 40px;
    height: 100%;
    position: relative;
    @include media-breakpoint-up(xl) {
        width: 250px;
    }
    .submenu-inner {
        width: 100%;
        height: 100%;
        border-top-left-radius: 1rem;
        padding: 24px;
        display: none;
        @include media-breakpoint-up(xl) {
            display: block;
        }

        .submenu-header {
            a {
                width: 26px;
                height: 26px;
                background: $color-black-light;
                border-radius: 100%;
                text-align: center;
                line-height: 27px;

                &:last-child {
                    // margin-left: 14px;
                    // margin-right: -36px;
                    z-index: 50;
                }
                &:hover {
                    background: $color-black-100;
                }
            }
        }

        .submenu-nav {
            margin: 0 -10px;
            > li {
                > a {
                    font-family: $font-family-regular;
                    color: $color-black;
                    text-decoration: none !important;
                    padding: 10px;
                    display: block;
                    border-radius: 3px;
                    transition: all 1s;
                    &:hover {
                        background: $color-black-light;
                    }
                }
                .active {
                    background: $color-blue-light !important;
                }
            }
        }
        .submenu-viewall {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: $color-black-lighter;
            display: block;
            color: $color-black-300;
            padding: 34px 24px;
            i {
                color: $color-black-300;
                margin-top: 1px !important;
            }
        }
    }
    .submenu-minimized-trigger {
        width: 100%;
        height: 100%;
        border-top-left-radius: 1rem;
        position: relative;
        @include media-breakpoint-up(xl) {
            display: none;
        }

        a {
            width: 26px;
            height: 26px;
            background: $color-black-light;
            border-radius: 100%;
            text-align: center;
            line-height: 27px;
            z-index: 50;
            position: absolute;
            top: 24px;
            right: -12px;

            &:hover {
                background: $color-black-100;
            }
        }
    }
}
.submenu-minimized {
    .submenu {
        width: 40px;
        .submenu-inner {
            display: none;
        }
        .submenu-minimized-trigger {
            display: block;
        }
    }
}

.v-scroll {
    overflow-x: hidden;
    height: 520px;
    overflow-y: scroll;
}
