@import "../../assets/styles/mixins";

// New css
.main-search {
    width: 100%;
    height: 100%;
    padding-right: 40px;
    display: block;
    .search-inner {
        background: $color-black-dark;
        color: #fff;
        padding-left: 55px;
        padding-right: 40px;
        position: relative;
        .hide-search {
            position: absolute;
            right: -14px;
            top: 24px;
            width: 26px;
            height: 26px;
            background: $color-black-light;
            border-radius: 100%;
            text-align: center;
            line-height: 27px;
            display: block;
        }
        .text-muted {
            color: $color-black-200 !important;
        }
        .search-header {
            input {
                background: none;
                font-size: 2rem;
                color: #fff;
                padding: 10px;
                border: none;
                border-bottom: solid 3px $color-black-300;
                width: 100%;
                outline: none !important;
                box-shadow: none !important;
            }
            .nav {
                margin-top: 20px;
                .nav-link {
                    padding: 5px 15px;
                    color: #fff;
                    border-radius: 3px;
                    &.active {
                        background: $color-black-300;
                    }
                }
            }
        }
        .saerch-pagination {
            a {
                display: inline-block;
                padding: 5px 0;
                margin-right: 10px;
                padding-right: 15px;
                color: $color-black-200;
                &.active {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
}

.disabled {
    cursor: default;
}

.vertical-scroll {
    overflow-x: hidden;
    height: 380px;
    overflow-y: scroll;
}

.search-display {
    border-bottom: #404346 solid 1px;
    padding-bottom: 15px;
}