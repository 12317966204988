@import "../../assets/styles/mixins";

/*notifications*/
.notifications {
    .drawer-inner {
        .drawer-content {
            width: 550px;

            .drawer-content-body {
                padding-left: 70px;
                padding-top: 0px;
                .notification-item {
                    position: relative;
                    .read-unread {
                        width: 10px;
                        height: 10px;
                        background: $color-black-200;
                        border-radius: 50%;
                    }
                    &.unread {
                        .read-unread {
                            background: $color-blue;
                        }
                    }
                }
            }
            .notifications-empty {
                img {
                    width: 300px;
                }
            }
        }
    }
}

.drawer-inner {
    width: 100%;
    height: 100%;
    position: relative;
    .drawer-backdrop {
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background: #000000;
    }
    .drawer-content {
        width: 420px;
        background: #fff;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        .drawer-content-header {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: start;
            align-items: flex-start;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            padding: 30px 30px 15px 30px;
            width: 100%;
        }
        .drawer-content-body {
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            // overflow: auto;
            // overflow-x: hidden;
            // overflow-y: scroll;
            // height: 520px;
            padding: 15px 30px 30px 30px;
        }
        .drawer-content-footer {
            padding: 30px;
            border-top: 1px solid $color-black-light;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            width: 100%;
            text-align: left;
        }
        &.drawer-content-left {
            left: 0;
            right: auto;
        }
    }
}
