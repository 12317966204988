@import "../../assets/styles/mixins";
.flow-card {
    .flow-card-header-btn {
        display: block;
        width: 100%;
        height: 90px;
        &.blue-pattern {
            background: url(/images/flow-card-bg2.png) center center no-repeat;
            background-size: cover;
            i {
                color: $color-blue;
            }
        }
        &.green-pattern {
            background: url(/images/flow-card-bg3.png) center center no-repeat;
            background-size: cover;
            i {
                color: $color-green;
            }
        }
        &.yellow-pattern {
            background: url(/images/flow-card-bg4.png) center center no-repeat;
            background-size: cover;
            i {
                color: $color-yellow-dark;
            }
        }
        &.purple-pattern {
            background: url(/images/flow-card-bg5.png) center center no-repeat;
            background-size: cover;
            i {
                color: $color-purple;
            }
        }
    }
}
.flow-card-link {
    color: $color-black !important;
    text-decoration: none !important;
}
