@import "../../../assets/styles/mixins";
@import "../../../assets/styles/bootstrap/bootstrap";

.main-menu {
    width: 75px;
    position: relative;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        > li {
            padding: 0;
            margin: 0;
            list-style: none;
            position: relative;
            width: 100%;

            > .main-menu-link {
                display: block;
                width: 44px;
                height: 44px;
                line-height: 54px;
                margin: auto;
                text-align: center;
                color: $color-black-300;
                margin-top: 0.5rem;
                border-radius: 50%;

                > i {
                    font-size: 1.3rem;
                    color: $color-black-300;
                    transition: color 1s;
                }
                &:hover {
                    i {
                        color: #fff;
                    }
                }
            }
            .active {
                i {
                    color: #fff;
                }
            }
        }
    }
    .main-menu-bottom-nav {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
    }
}

.submenu {
    width: 40px;
    height: 100%;
    position: relative;
    @include media-breakpoint-up(xl) {
        width: 250px;
    }
    .submenu-inner {
        width: 100%;
        height: 100%;
        border-top-left-radius: 1rem;
        padding: 24px;
        display: none;
        @include media-breakpoint-up(xl) {
            display: block;
        }

        .submenu-header {
            a {
                width: 26px;
                height: 26px;
                background: $color-black-light;
                border-radius: 100%;
                text-align: center;
                line-height: 27px;

                &:last-child {
                    margin-left: 14px;
                    margin-right: -36px;
                    z-index: 50;
                }
                &:hover {
                    background: $color-black-100;
                }
            }
        }

        .submenu-nav {
            margin: 0 -10px;
            > li {
                > a {
                    font-family: $font-family-regular;
                    color: $color-black;
                    text-decoration: none !important;
                    padding: 10px;
                    display: block;
                    border-radius: 3px;
                    transition: all 1s;
                    &:hover {
                        background: $color-black-light;
                    }
                }
                .active {
                    background: $color-blue-light !important;
                }
            }
        }
        .submenu-viewall {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: $color-black-lighter;
            display: block;
            color: $color-black-300;
            padding: 34px 24px;
            i {
                color: $color-black-300;
                margin-top: 1px !important;
            }
        }
    }
    .submenu-minimized-trigger {
        width: 100%;
        height: 100%;
        border-top-left-radius: 1rem;
        position: relative;
        @include media-breakpoint-up(xl) {
            display: none;
        }

        a {
            width: 26px;
            height: 26px;
            background: $color-black-light;
            border-radius: 100%;
            text-align: center;
            line-height: 27px;
            z-index: 50;
            position: absolute;
            top: 24px;
            right: -12px;

            &:hover {
                background: $color-black-100;
            }
        }
    }
}
.submenu-minimized {
    .submenu {
        width: 40px;
        .submenu-inner {
            display: none;
        }
        .submenu-minimized-trigger {
            display: block;
        }
    }
}

.dark-bg {
    background: #26292C !important;
}