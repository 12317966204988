@import "../mixins";
h1,
h2,
h3,
h4,
h5,
h6,
strong {
    font-family: $font-family-medium;
}
strong {
    font-weight: normal !important;
}
.fw-light {
    font-family: $font-family-base !important;
}
.fw-regular {
    font-family: $font-family-regular !important;
}
.fw-medium {
    font-family: $font-family-medium !important;
}
