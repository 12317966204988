// Old loader CSS
// $blinkMs: 185ms;
// $exaggeratedMs: 1270ms;

// .dots-loader {
//   letter-spacing: 0.6em;
//   text-indent: 0.6em;

//   > * {
//     animation: $exaggeratedMs infinite ease-in-out both;
//     animation-name: scale-down;
//     display: inline-block;

//     &:nth-child(1) {
//       animation-delay: -$blinkMs * 2;
//     }
//     &:nth-child(2) {
//       animation-delay: -$blinkMs;
//     }
//   }
// }

// @keyframes scale-down {
//   0%,
//   20%,
//   100% {
//     transform: scale(0);
//   }

//   60% {
//     transform: scale(1);
//   }
// }

// New loader CSS
.progress-dots i {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #9b9b9c;
    // vertical-align: middle;
}

button .progress-dots {
    margin: 0;
    padding: 0;
}

button .progress-dots i {
    background: #fff;
}

.progress-dots i:first-child {
    transform: translate(-5px);
    animation: progress-dots2 0.5s linear infinite;
    opacity: 0;
}

.progress-dots i:nth-child(2),
.progress-dots i:nth-child(3) {
    animation: progress-dots3 0.5s linear infinite;
}

.progress-dots i:last-child {
    animation: progress-dots1 0.5s linear infinite;
}

@keyframes progress-dots1 {
    to {
        transform: translate(10px);
        opacity: 0;
    }
}

@keyframes progress-dots2 {
    to {
        transform: translate(5px);
        opacity: 1;
    }
}

@keyframes progress-dots3 {
    to {
        transform: translate(5px);
    }
}

// Overlay loader css
.overlay {
    /* Position */
    left: 0;
    position: fixed;
    top: 0;

    /* Take full size */
    height: 100%;
    width: 100%;

    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 100;

    /* Add a dark background */
    background-color: rgba(0, 0, 0, 1);
    opacity: 0.45;
}
