@import '../mixins';
.table-no-header {
    tr {
        &:first-child {
            td {
                border: none;
            }
        }
    }
}
.show-records {
    select {
        width: 60px;
    }
}

