// Base colors

$color-green: #08a742;
$color-green-dark: #0d923e;
$color-green-100: #61c786;
$color-green-light: #d7f1e1;

$color-black: #26292c;
$color-black-dark: #202225;
$color-black-400: #404346;
$color-black-300: #747678;
$color-black-200: #cbcccd;
$color-black-100: #dcdddd;
$color-black-light: #eeeeee;
$color-black-lighter: #f7f7f7;

$color-gray: #747678;

$color-blue: #317ae2;
$color-blue-dark: #2f6cc5;
$color-blue-100: #7baaed;
$color-blue-light: #cedff8;
$color-blue-lighter: #deeafa;

$color-red: #f94839;
$color-red-dark: #d64336;
$color-red-100: #fb8a80;
$color-red-light: #fed3d0;

$color-yellow: #ffcc00;
$color-yellow-dark: #ddb207;
$color-yellow-100: #ffdf5c;
$color-yellow-light: #fff7d6;

$color-purple: #721ea9;
$color-purple-dark: #661f95;
$color-purple-100: #a56fc8;
$color-purple-light: #e8dbf1;

// Accent colors
$primary: $color-blue;
$brand: $color-green;
//$secondary:
$success: $color-green;
$info: $color-blue;
$purple: $color-purple;
$warning: $color-yellow;
$danger: $color-red;
$light: $color-black-lighter;
$dark: $color-black;

$body-color: $color-black;
$link-color: $color-blue;
$border-color: $color-black-light;
$input-border-color: $color-black-100;
$card-border-color: $color-black-light;
$card-spacer-x: 1rem;
$table-hover-bg: $light;

$font-family-base: "hero_newlight", sans-serif;
$font-family-regular: "hero_newregular", sans-serif;
$font-family-medium: "hero_newmedium", sans-serif;
$font-family-semibold: "hero_newsemibold", sans-serif;

$font-size-base: 0.875rem; /* 14px */
$h1-font-size: 1.5rem; /* 24px */
$h4-font-size: 1.125rem; /* 18p x*/
$h5-font-size: 1rem; /* 16px */
$h6-font-size: 0.875rem;
