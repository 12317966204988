.initiate-flow {
    position: relative;
    padding: 0 65px;
    color: #fff;
}

.search-flow-input {
    width: 700px !important;
    height: 48px !important;
}

.search-results {
    border: #404346 solid 1px;
    list-style-type: none;
    padding-left: 0px;
    width: 700px;
    img {
        margin-top: 3px;
        margin-left: 10px;
    }
    .search-item {
        cursor: pointer;
        height: 70px;
        border-bottom: #404346 solid 1px;
        padding-top: 15px;
    }
}

.search-results-text {
    width: 700px;
}
.search-bg :hover {
    background: #2f6cc5;
}

.vertical-scroll {
    overflow-x: hidden;
    height: 380px;
    overflow-y: scroll;
}
