@import "../../assets/styles/mixins";
@import "../../assets/styles/custom/navsidebar";
@import "../../assets/styles/custom/typography";
@import "../../assets/styles/custom/core";
@import "../../assets/styles/custom/tabs";
@import "../../assets/styles/custom/tables";

body {
    height: 100%;
    overflow-x: hidden;
}
i {
    color: $color-black-400;
}
