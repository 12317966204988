@import "../../../assets/styles/mixins";

.search-input-container {
    display: flex;
    align-items: center;
    padding: 15px 1.5rem;
    .search {
        flex-grow: 1;
        position: relative;
        input {
            padding-left: 40px;
            &:focus + span,
            &:active + span {
                color: $color-black;
            }
        }

        span {
            display: inline-block;
            position: absolute;
            top: 13px;
            left: 12px;
            color: $color-gray;
        }
    }
    .labels {
        width: 200px;
    }
    .settings {
        width: 100px;
    }
}
